import $ from "jquery";
import showdown from "showdown";

export function init() {
	$(document).ready(function () {

		$('#message-send').submit(function (e) {
			e.preventDefault(); // Prevent default form submission
			var message = $(this).find('input[type="text"]').val();
			$(this).find('input[type="text"]').val(''); // Clear input field

			if (!message) return; // Do nothing if message is empty

			$('#messages').scrollTop($('#messages')[0].scrollHeight);

			// Add the user message to the chat display
			$('#messages').append(`
				<div class="chat-message">
					<div class="flex items-end">
						<div class="flex flex-col space-y-2 text-base max-w-xs mx-2 order-2 items-start">
							<div><span class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
								${message}
							</span></div>
						</div>
					</div>
				</div>
			`);

			$('#messages').append(`
				<div class="chat-message typing">
					<div class="flex items-end justify-end">
						<div class="flex flex-col space-y-2 text-base max-w-xs mx-2 order-1 items-end">
							<div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white">
								<svg height="40" width="40" class="loader">
									<circle class="dot" cx="10" cy="20" r="3" style="fill:grey;" />
									<circle class="dot" cx="20" cy="20" r="3" style="fill:grey;" />
									<circle class="dot" cx="30" cy="20" r="3" style="fill:grey;" />
								</svg>
							</span></div>
						</div>
					</div>
				</div>
			`);

			// Send the message to the backend controller
			$.ajax({
				url: '/actions/codechecker/chat/ask',
				type: 'GET',
				data: {
					question: message
				},
				success: function (response) {
					$('.chat-message.typing').remove();

					if (response.text) {
						var assistantMessage = response.text.replace(/(?:\r\n|\r|\n)/g, '<br>'); // Convert newline to <br>

						
						console.log(assistantMessage);
						//Convert markdown to HTML in assistant message
						var converter = new showdown.Converter();
						assistantMessage = converter.makeHtml(assistantMessage);
						console.log('markdown to html');	
						console.log(assistantMessage);	

						$('#messages').append(`
							<div class="chat-message">
								<div class="flex items-end justify-end">
									<div class="flex flex-col space-y-2 text-base max-w-xs mx-2 order-1 items-end">
										<div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white">
											${assistantMessage}
										</span></div>
									</div>
								</div>
							</div>
						`);

						$('#messages').scrollTop($('#messages')[0].scrollHeight);
					}
				},
				error: function (xhr, status, error) {
					$('.chat-message.typing').remove();
					$('#messages').append(`
						<div class="chat-message">
							<div class="flex items-end justify-end">
								<div class="flex flex-col space-y-2 text-base max-w-xs mx-2 order-1 items-end">
									<div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-red-500 text-white">
										Sorry, an error occurred and I could not answer. Please try again.
									</span></div>
								</div>
							</div>
						</div>
					`);
					console.error("Error sending message:", error);
				}
			});
		});
	});
}
