import $  from 'jquery';
import Glide from '@glidejs/glide';
import AOS from 'aos';
import featherlight from 'featherlight';
import lazysizes from "lazysizes";
import showdown from 'showdown';


// export for others scripts to use
window.$ = $;
window.Glide = Glide;
window.AOS = AOS;
window.featherlight = featherlight;
window.lazysizes = lazysizes;
window.showdown = showdown;


import { init as header } from './modules/header';
import { init as home } from './modules/home';
import { init as pdf } from './modules/pdf';
import { init as chat } from './modules/chat';



// App main
const main = async () => {
    // Async load the Vue 3 APIs we need from the Vue ESM
    // Create our vue instance

};

// Execute async function
main().then( (root) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}


$(function () {
    AOS.init({
        once: true
    });
});

document.querySelectorAll('img')
    .forEach((img) => {
        if(img.complete) {
            if ($(img).hasClass('animate-pulse bg-site-loading')) {
                $(img).removeClass('animate-pulse bg-site-loading');
            }
        }
        img.onload = function () {
            AOS.refresh();
            if($(img).hasClass('animate-pulse bg-site-loading')) {
                $(img).removeClass('animate-pulse bg-site-loading');
            }
        }
    });


header();
home();
pdf();
chat();